import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.scss"
import banner from "../images/juicybanner.png"
import { Helmet } from "react-helmet"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

export const IndexPageTemplate = ({
  feature,
  instagram,
  facebook,
  youtube,
  twitter,
  email,
  shows,
  featureVideo,
}) => {
  const showsUI = shows.map(show => {
    return (
      <div className="tile">
        <h2 style={{ color: "white" }}>{show.venue}</h2>
        <p className="yellow">{show.date}</p>
      </div>
    )
  })

  function getId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[2].length === 11 ? match[2] : null
  }

  const videoID = getId(featureVideo)

  return (
    <>
      <Helmet>
        <script src="https://identity.netlify.com/v1/netlify-identity-widget.js"></script>
      </Helmet>
      <SEO title="Home" />
      <div className="heroSection">
        <div className="podcastContainer">
          <StaticImage
            src="../images/podcast1.png"
            placeholder="blurred"
            alt="Back in The Day Day Banner"
            layout="fullWidth"
          />

          <a
            className="altButton"
            target="_blank"
            href="https://open.spotify.com/show/6yDUsSjX8aULLwrTYWhePI?si=aYxAuX9XRhy5WjoXCOwQcA"
          >
            Episodes
          </a>

          <a
            className="altButton"
            target="_blank"
            href="https://dedicated-founder-2746.ck.page/02a94ba0d8"
          >
            Join The Newsletter
          </a>

          <div>
            <div style={{ padding: "20px 0px 0px 8px" }}>
              <a target="_blank" href={instagram}>
                <FontAwesomeIcon className="socialIcons" icon={faInstagram} />
              </a>
              <a target="_blank" href={twitter}>
                {" "}
                <FontAwesomeIcon className="socialIcons" icon={faTwitter} />
              </a>
              <a target="_blank" href={facebook}>
                {" "}
                <FontAwesomeIcon className="socialIcons" icon={faFacebook} />
              </a>
              <a target="_blank" href={youtube}>
                {" "}
                <FontAwesomeIcon className="socialIcons" icon={faYoutube} />
              </a>
            </div>
            <p style={{ color: "white", marginLeft: "7px" }}>{email}</p>
          </div>
        </div>
      </div>

      <div className="feature">
        <h1 className="white">Back In The Day Day</h1>
        <h2 className="alt">Latest Episode</h2>

        <div className="thumbnail">
          <img
            className="thumbnailImage"
            alt="Latest Episode of Back in The Day Day"
            src={
              !!feature.image.childImageSharp
                ? feature.image.childImageSharp.fluid.src
                : feature.image
            }
          />
          <a target="_blank" href={feature.link}>
            <div className="thumbnailContainer">
              <div className="banner">
                <h1 className="white">{feature.title}</h1>
                <p className="white">{feature.description}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        className="sectionTwo"
      >
        <img className="juicyBanner" src={banner} />
        <div className="labelGrid">
          <Link to="/podcast">
            <div className="label">
              <h3 className="white">Back in The Day Day</h3>
            </div>
          </Link>
          <Link to="/shows">
            <div className="label">
              <h3 className="white">Shows</h3>
            </div>
          </Link>
          <Link to="/juicyjogger">
            <div className="label">
              <h3 className="white">Juicy Jogger</h3>
            </div>
          </Link>
          <Link to="/about">
            <div className="label">
              <h3 className="white">About</h3>
            </div>
          </Link>{" "}
          <a target="_blank" href="https://burginton.typeform.com/to/l5BCfnZ3">
            <div className="label">
              <h3 className="white">Contact</h3>
            </div>
          </a>
          <a target="_blank" href="https://michelpasvar.bigcartel.com/">
            <div className="label">
              <h3 className="white">Merch</h3>
            </div>
          </a>
        </div>
      </div>
      <div className="sectionOne">
        <div className="container">
          <div className="content">
            <h1
              style={{
                color: "white",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Upcoming Shows
            </h1>
            {showsUI}
          </div>
        </div>
      </div>

      <div className="sectionTwo">
        <h1 style={{ textAlign: "center" }} className="white">
          Videos
        </h1>

        <div className="tileThree">
          <div class="video-container">
            <iframe
              height="auto"
              src={`https://www.youtube.com/embed/${videoID}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="redDivider"></div>
    </>
  )
}

IndexPageTemplate.propTypes = {
  feature: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  instagram: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  youtube: PropTypes.string,
  email: PropTypes.string,
  episodeLink: PropTypes.string,
  featureVideo: PropTypes.string,
}

const IndexPage = ({ data }) => {
  console.log(data)
  const frontmatterIndex = data.index.frontmatter
  const frontmatterShows = data.shows.frontmatter

  return (
    <Layout>
      <IndexPageTemplate
        instagram={frontmatterIndex.instagram}
        twitter={frontmatterIndex.twitter}
        facebook={frontmatterIndex.facebook}
        youtube={frontmatterIndex.youtube}
        email={frontmatterIndex.email}
        feature={frontmatterIndex.feature}
        shows={frontmatterShows.shows}
        featureVideo={frontmatterIndex.featureVideo}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    index: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        feature {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
          link
          title
        }
        instagram
        twitter
        facebook
        youtube
        email
        featureVideo
      }
    }
    shows: markdownRemark(frontmatter: { templateKey: { eq: "shows-page" } }) {
      frontmatter {
        shows {
          date(formatString: "MMMM Do, YYYY")
          venue
        }
      }
    }
  }
`
